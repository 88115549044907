<template>
    <card :to="ad ? ad.link : null" target="_blank" v-bind="cardProps">
        <slot/>
        <cdn-image v-if="ad && ad.image" :src="ad.image" :alt="$t('label.ad')" :ratio="4/2" :width="380" cover/>

        <div v-if="ad" class="flex flex-column justify-center px-8 sm:px-16 py-8 min-h-36 flex-grow-1">
            <span v-if="ad.name" class="truncate text-xs font-semi-bold leading-normal font-heading text-gray-500">{{ ad.name }}</span>
            <span class="leading-normal text-subtext-color text-2xs">{{ $t('label.ad') }}</span>
        </div>

    </card>
</template>

<script>
import {CardMixin} from "@spoferan/nuxt-spoferan/mixins";

export default {
    name: 'AdCard',

    mixins: [CardMixin],

    props: {
        ad: {
            type: Object,
            default: false
        }
    }
}
</script>
